@mixin transition($dur,$prop){
    transition: $prop  $dur;
    -o-transition: $prop  $dur;
    -moz-transition: $prop  $dur;
    -ms-transition: $prop  $dur;
    -webkit-transition: $prop  $dur;
}

@font-face {
    font-family: ExpoArabic;
    font-weight: 400px;
    src: url('./assets/ExpoArabic-Book.ttf');
  }
  body{
    overflow-x: hidden !important;
    font-family: ExpoArabic !important;
    background-color: whitesmoke;
  }
nav{
    padding: 10px 30px ;
    .navImage{ 
        width: 10% !important;
        img{
            width: 100% !important;
        }
    }
        
    ul{
        li{ 
            h4{
                cursor: pointer;
            }
        }
    }
}

#nav2{
    background-color:#0C8ACB; 
    ul{

        li{

            a{
                color: white !important;
                font-size: 22px;
                @include transition(0.3s,all);
                &:hover{
                    color: rgb(255, 42, 42)!important;
                    text-decoration: underline;
                }
            }
        }
    }
}
#nav1{
    background-color: #FFF6E9 !important;
    ul{

        li{

            h6,a{
                color: #0C8ACB !important;
                @include transition(0.3s,all);
                text-align: center;
                cursor: pointer;
                &:hover{
                    color: #0a5e88 !important;
                    text-decoration: underline;
                    text-decoration-color: #0C8ACB !important;
                }
            }
        }
    }
}

#Home{
    margin: auto;
    .slick-dots{
        top:90% !important;
        left:42% !important;
        button::before{
            font-size: 16px !important;
            color: white !important;
        }
        .slick-active{
            color: #166606 !important;
        }
    }
    .slick-next{
        right: 2% !important;
        color: #166606 !important;
        border-radius: 8px !important;
        z-index: 9999999;
    }
    .slick-prev{ 
        left: 2% !important;
        color: #166606 !important;
        border-radius: 8px !important;
        z-index: 9999999;
    }
}

#AboutUs{

    h2{
        font-size: 50px;
        position: relative;
        color: #0C8ACB;
        &::after{
            content: "";
            width: 30%;
            height: 5px;
            position: absolute;
            background-color: #0C8ACB;
            margin: auto;
            left:0%;
            right: 0%;
            top: 105%;
        }
    }
    p{
        font-size: 24px;
    }
}
#OurServices{
    h2{
        color: #0C8ACB !important;
        font-size: 50px;
        position: relative;
        &::after{
            content: "";
            width: 15%;
            height: 5px;
            position: absolute;
            background-color: #0C8ACB ;
            margin: auto;
            left:0%;
            right: 0%;
            top: 105%;
        }
    }
    p{
        color: rgb(0, 0, 0);
        font-size: 24px;
    }
}

#OurDatesJourney{

    h2{
        font-size: 50px;
        color: #0C8ACB !important;
        position: relative;
        &::after{
            content: "";
            width: 60%;
            height: 5px;
            position: absolute;
            background-color: #0C8ACB !important;
            left:0%;
            right: 0%;
            margin: auto;
            top: 105%;
        }
    }
    p{
        font-size: 24px;
    }
    h3{
        color: #0C8ACB !important;
        font-size: 35px;
        font-weight: 600;
    }

}

#OurGallery{
    h2{
        font-size: 50px;
        position: relative;
        color: #0C8ACB !important;
        &::after{
            content: "";
            position: absolute;
            top: 115%;
            bottom: 0%;
            right: 0%;
            left: 0%;
            width: 15%;
            height: 5px;
            margin: auto;
            background-color: #0C8ACB;

        }
    }
    .editIcon{
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #166606;
    }
}
.categoryLinksContainer{
    width: 100%;
    margin: auto;
}

#footer{
    border-top: 5px solid #0C8ACB;
    background-color:#4C4C4C;
    h3{
        font-size: 25px;
        position: relative;
        margin-bottom: 30px !important;
        color: #0C8ACB !important;
        &::after{
            content: "";
            position: absolute;
            top: 115%;
            bottom: 0%;
            right: 0%;
            left: 0%;
            width: 15%;
            height: 5px;
            margin: auto;
            background-color: #0C8ACB;

        } 
    }
    #footerContact div p{
        color: white !important;
    }
    .footerIcons{
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #0C8ACB;
        color: white;
        font-size: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    #footerMainLinks{
        h6{
            cursor: pointer;
            color: white; 
        }
        a{
            color: white !important;
        }
    }
    
}

#OurMissinAndOurVision{
    h2{
        font-size: 45px;
        font-weight: 600;
        color: #0C8ACB !important;
        margin-bottom: 30px;
        position: relative;
        &::after{
            content: "";
            position: absolute;
            top: 115%;
            bottom: 0%;
            right: 0%;
            left: 0%;
            width: 15%;
            height: 5px;
            margin: auto;
            background-color: #0C8ACB;
        }
    }
    p{
        font-size: 20px;
        font-weight: 400;
    }
}

#ContactUs{
    h2{
        font-size: 45px;
        font-weight: 600;
        color: #0C8ACB !important;
        margin-bottom: 30px;
        position: relative;
        &::after{
            content: "";
            position: absolute;
            top: 115%;
            bottom: 0%;
            right: 0%;
            left: 0%;
            width: 15%;
            height: 5px;
            margin: auto;
            background-color: #0C8ACB;
        }
    }
}

#specificCategory{
    h2{
        font-size: 45px;
        font-weight: 600;
        color: #0C8ACB !important;
        margin-bottom: 30px;
        position: relative;
        &::after{
            content: "";
            position: absolute;
            top: 115%;
            bottom: 0%;
            right: 0%;
            left: 0%;
            width: 15%;
            height: 5px;
            margin: auto;
            background-color: #0C8ACB;
        }
    }
}

.categoryProductsSize{
    h3{
        font-size: 16px !important;
    }
}

.containerBox{
    @include transition(0.3s,all);
    &:hover{
        transform: scale(1.1 , 1.1); 
    }
}

body,*{
    scroll-behavior: unset !important;
}
@media screen and (max-width:800px) { 
    #footer p{
        font-size: 11px;
    }
}

@media screen and (max-width:1400px) {
    #OurGallery{
        h3{
            font-size: 16px;
        }
        figure{
            padding: 0px !important;
        }
    }
    #allcategoriesAndProductsControl{
        button{
            margin-bottom: 10px;
        }
        figure{
            figcaption h2,h3{
                font-size: 22px !important;
            }
        }
        
    }
    .categoryProductsSize{
        h3{
            font-size: 16px !important;
        }
    }
    
}
@media screen and (max-width:1000px) {
    #nav2{
        ul{
            li{
                a{
                    font-size: 19px;
                }
            }
        }
    }
    #OurGallery{
        h3{
            font-size: 12px;
        }
        h2{
            font-size: 40px;
        }
        figure{
            padding: 0px !important;
            figcaption{
                padding-right: 0px !important;
                padding-left: 0px !important;
            }
        }
    }
    #AboutUs{
        h2{
            font-size: 35px;
        }
        p{
            font-size: 16px;
        }
    }
    #OurServices{
        h2{
            font-size: 40px;
        }
        p{
            font-size: 16px;
        }
    }
    #OurDatesJourney{
        h2{
            font-size: 35px;
        }
        h3{
            font-size: 30px;
        }
        p{
            font-size: 16px;
        }
    }
    #OurMissinAndOurVision{
        h2{
            font-size: 40px;
        }
        p{
            font-size: 16px;
        }
    }
    #ContactUs{
        h2{
            font-size: 40px;
        }
        p{
            font-size: 16px;
        }
    }
    #specificCategory{
        h2{
            font-size: 40px;
        }
        p{
            font-size: 16px;
        }
    }
    #allcategoriesAndProductsControl{
        .col-12{
            .controlTitles{
                font-size: 25px !important;
            }
        }
        .col-3{
            .controlTitles{
                font-size: 25px !important;
            }
        }
        button{
            font-size: 14px !important;
        }
    }
    #nav1{
        ul{
    
            li{
    
                h6,a{
                    font-size:14px !important;
                }
            }
        }
    }
    .categoryProductsSize{
        h3{
            font-size: 12px !important;
        }
    }
    .categoryText{
        h3{
            font-size: 20px !important;
        }
        h5{
            font-size: 17px !important;
        }
    }
    .col-3 .containerBox{
        padding: 0px !important;
        figcaption{
            padding-right: 0px !important;
            padding-left: 0px !important;
        }
    }
}

@media screen and (max-width:800px) {
    nav{
        .navImage{ 
            width: 15% !important;
        }
    }
    #AboutUs{
        h2{
            font-size: 25px;
        }
        p{
            font-size: 14px;
        }
    }
    #allcategoriesAndProductsControl{
        figure{
            figcaption h2,h3{
                font-size: 18px !important;
            }
        }
        button{
            font-size: 12px !important;
        }
        
    }
    #nav1{
        .navImage{
            width: 30%;
        }
        ul{
    
            li{
    
                h6,a{
                    font-size: 12px !important;
                }
            }
        }
    }
    #nav2{
        ul{
            li{
                a{
                    font-size: 8px;
                }
            }
        }
    }
    .categoryProductsSize{
        h3{
            font-size: 8px !important;
        }
    }
    .categoryText{
        h3{
            font-size: 14px !important;
        }
        h5{
            font-size: 12px !important;
        }
    }
}

@media screen and (max-width:520px) {
    #OurGallery{
        h3{
            font-size: 8px;
        }
        h2{
            font-size: 25px;
        }
    }
    #OurServices{
        h2{
            font-size: 25px;
        }
        p{
            font-size: 14px;
        }
    }
    #OurDatesJourney{
        h2{
            font-size: 25px;
        }
        h3{
            font-size: 20px;
        }
        p{
            font-size: 14px;
        }
    }
    #OurMissinAndOurVision{
        h2{
            font-size: 22px;
        }
        p{
            font-size: 14px;
        }
    }
    #ContactUs{
        h2{
            font-size: 25px;
        }
        p{
            font-size: 14px;
        }
    }
    #specificCategory{
        h2{
            font-size: 25px;
        }
        p{
            font-size: 14px;
        }
    }

    #allcategoriesAndProductsControl{
        figure{
            figcaption h2,h3{
                font-size: 16px !important;
            }
        }
        button{
            font-size: 8px !important;
        }
    }
    #nav2{
        ul{
            li{
                a{
                    font-size: 10px;
                }
            }
        }
    }
    .categoryProductsSize{
        h3{
            font-size: 4px !important;
        }
    } 

    footer{
        p{
            font-size: 8px !important;
        }
        .footerIcons{
            width: 30px !important;
            height: 30px !important;
            font-size: 10px !important;
        }
    }
    .categoryText{
        h3{
            font-size: 8px !important;
        }
        h5{
            font-size: 6px !important;
        }
    }
}



